import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import Dropzone, {
  IFileWithMeta,
  StatusValue,
  IUploadParams,
} from 'react-dropzone-uploader'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import {AUDIT_INGEST_SHARED_SECRET} from 'config/environment'

const classes = makeClasses((theme) => ({
  dropzone: {
    marginTop: theme.spacing(2),
    borderColor: '#C0C0C0',
    borderWidth: 1,
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  inputLabelWithFiles: {
    display: 'none',
  },
}))

export interface UploadedFile {
  name: string
}

interface Props {
  onFileUpload: (opts: {file: UploadedFile}) => void
}

const AuditCsvUploader: FC<Props> = flowMax(
  addDisplayName('AuditCsvUploader'),
  addHandlers({
    getUploadParams: () => async (): Promise<IUploadParams> => ({
      headers: {
        authorization: AUDIT_INGEST_SHARED_SECRET,
      },
      url: '/audit-ingest',
      method: 'POST',
    }),
    handleChangeStatus: ({onFileUpload}) => (
      {meta: {name}}: IFileWithMeta,
      status: StatusValue
    ) => {
      if (status === 'done') {
        onFileUpload({file: {name}})
      }
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  ({getUploadParams, handleChangeStatus, classes, t}) => (
    <Dropzone
      accept="text/csv"
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      addClassNames={classes}
      inputContent={t('documentUpload.instructions')}
      inputWithFilesContent={null}
      multiple={false}
      maxFiles={1}
    />
  )
)

export default AuditCsvUploader
