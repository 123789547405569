import React, {FC, ComponentType, ReactElement} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addStateHandlers,
  addEffect,
  addProps,
  addHandlers,
} from 'ad-hok'
import {Theme} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'

import {addClasses, makeClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import Fab from 'components/Fab'
import Grid from 'components/Grid'
import Link from 'components/Link'
import {createAccountPath} from 'components/TopLevelRoutes'
import Tabs from 'components/Tabs'
import Tab from 'components/Tab'
import TabPanel from 'components/TabPanel'
import Badge from 'components/Badge'
import TriageWorklist from 'components/TriageWorklist'
import ApplicationWorklist, {
  UNREVIEWED_EDITABLE_FILES_FILTER_UNREVEVIEWED_REMOTE_REQUEST,
} from 'components/ApplicationWorklist'
import AccountWorklist from 'components/AccountWorklist'
import AuditWorklist from 'components/AuditWorklist'
import PersonWorklist from 'components/PersonWorklist'
import TaskWorklist from 'components/TaskWorklist'
import {
  getLastWorklistTabName,
  setLastWorklistTabName,
} from 'utils/worklistPersistence'
import UnmatchedWebformsWorklist from 'components/UnmatchedWebformsWorklist'
import InboundWorklist from 'components/InboundWorklist'
import {
  addShowTriage,
  addShowAccounts,
  addShowAudits,
} from 'utils/configContext'
import {
  addNumberOfPeopleWithUnreviewedReceivedWebformsQuery,
  addMeQuery,
  addPeopleWithUnreadTextMessagesQuery,
} from 'graphql/generated'
import {Me_me} from 'graphql/deserializedTypes/Me'
import {addLoadingIndicator} from 'utils/dataLoading'

const classes = makeClasses((theme: Theme) => ({
  fab: {
    bottom: theme.spacing(4),
    right: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(4),
  },
  itemContainer: {
    marginTop: theme.spacing(2),
  },
  tab: {
    flexDirection: 'row-reverse',
  },
  tabLabelIcon: {
    minHeight: 0,
    paddingTop: '6px',
    '& $tab > *:first-child': {
      marginBottom: 0,
      marginLeft: theme.spacing(1),
    },
  },
  tabNotificationBadge: {
    '& svg': {
      marginLeft: 8,
    },
  },
}))

interface NotificationBadgeProps {
  count: number
  linkParams: Record<string, string>
  icon: React.ReactNode
}

const NotificationBadge: FC<NotificationBadgeProps> = flowMax(
  addDisplayName('NotificationBadge'),
  addClasses(classes),
  ({count, linkParams, icon, classes}) => (
    <Link
      to={`/?${new URLSearchParams(linkParams).toString()}`}
      className={classes.tabNotificationBadge}
    >
      <Badge
        badgeContent={count}
        color={count === 0 ? 'primary' : 'secondary'}
        showZero
      >
        {icon}
      </Badge>
    </Link>
  )
)

interface TabInfo {
  name: string
  Component: ComponentType
  getBadge?: () => ReactElement
}

const getTabs = ({
  showTriage,
  showAccounts,
  showAudits,
  me,
  numberOfPeopleWithUnreviewedReceivedWebforms,
  numberOfPeopleWithUnreadTextMessages,
}: {
  showTriage: boolean
  showAccounts: boolean
  showAudits: boolean
  me: Me_me
  numberOfPeopleWithUnreviewedReceivedWebforms: number
  numberOfPeopleWithUnreadTextMessages: number
}): TabInfo[] => [
  {
    name: 'inbounds',
    Component: InboundWorklist,
  },
  ...(showTriage
    ? [
        {
          name: 'triage',
          Component: TriageWorklist,
        },
      ]
    : []),
  {
    name: 'applications',
    Component: ApplicationWorklist,
    getBadge: () => (
      <>
        <NotificationBadge
          count={me.openApplicationsWithUnreadMessagesCount}
          linkParams={{
            unreadMessages: 'Yes',
            assignedToFilter: me.id,
          }}
          icon={<MessageOutlinedIcon />}
        />
        <NotificationBadge
          count={
            me.openApplicationsWithUnreviewedRemoteRequestEditableFileCount
          }
          linkParams={{
            unreviewedEditableFileFilter: UNREVIEWED_EDITABLE_FILES_FILTER_UNREVEVIEWED_REMOTE_REQUEST,
            assignedToFilter: me.id,
          }}
          icon={<DescriptionOutlinedIcon />}
        />
      </>
    ),
  },
  ...(showAccounts
    ? [
        {
          name: 'accounts',
          Component: AccountWorklist,
        },
      ]
    : []),
  {
    name: 'people',
    Component: PersonWorklist,
    getBadge: () => (
      <>
        <NotificationBadge
          count={numberOfPeopleWithUnreadTextMessages}
          linkParams={{
            unreadMessages: 'Yes',
            openApplications: 'noOpenApplications',
          }}
          icon={<MessageOutlinedIcon />}
        />
        <NotificationBadge
          count={numberOfPeopleWithUnreviewedReceivedWebforms}
          linkParams={{
            unreviewedWebformIds: 'all',
          }}
          icon={<DescriptionOutlinedIcon />}
        />
      </>
    ),
  },
  {
    name: 'tasks',
    Component: TaskWorklist,
  },
  // {
  //   name: 'reports',
  //   Component: Reports,
  // },
  ...(showAudits
    ? [
        {
          name: 'audit',
          Component: AuditWorklist,
        },
      ]
    : []),
  {
    name: 'unmatchedWebforms',
    Component: UnmatchedWebformsWorklist,
  },
]

const getLastWorklistTabIndex = (tabs: TabInfo[]): number => {
  const lastWorklistTabName = getLastWorklistTabName()
  const foundIndex = tabs.findIndex(({name}) => name === lastWorklistTabName)
  return foundIndex === -1 ? 0 : foundIndex
}

const Worklist: FC = flowMax(
  addDisplayName('Worklist'),
  addTranslationHelpers,
  addClasses(classes),
  addShowTriage,
  addShowAccounts,
  addShowAudits,
  addMeQuery({}),
  addNumberOfPeopleWithUnreviewedReceivedWebformsQuery({}),
  addPeopleWithUnreadTextMessagesQuery({}),
  addLoadingIndicator({}),
  addProps(
    ({
      showTriage,
      showAccounts,
      showAudits,
      me,
      numberOfPeopleWithUnreviewedReceivedWebforms,
      people: peopleWithUnreadTextMessages,
    }) => ({
      tabs: getTabs({
        showTriage,
        showAccounts,
        showAudits,
        me,
        numberOfPeopleWithUnreviewedReceivedWebforms,
        numberOfPeopleWithUnreadTextMessages:
          peopleWithUnreadTextMessages.length,
      }),
    }),
    [
      'showTriage',
      'showAccounts',
      'showAudits',
      'me',
      'numberOfPeopleWithUnreviewedReceivedWebforms',
      'people',
    ]
  ),
  addHandlers(
    {
      updateWorklistNotificationCounts: ({
        refetchNumberOfPeopleWithUnreviewedReceivedWebforms,
        refetchPeopleWithUnreadTextMessages,
        refetchMe,
      }) => () => {
        refetchNumberOfPeopleWithUnreviewedReceivedWebforms()
        refetchPeopleWithUnreadTextMessages()
        refetchMe()
      },
    },
    [
      'refetchNumberOfPeopleWithUnreviewedReceivedWebforms',
      'refetchPeopleWithUnreadTextMessages',
      'refetchMe',
    ]
  ),
  addStateHandlers(
    ({tabs}) => ({
      activeTabIndex: getLastWorklistTabIndex(tabs),
    }),
    {
      setActiveTabIndex: () => (_e: any, activeTabIndex: number) => ({
        activeTabIndex,
      }),
    }
  ),
  addEffect(
    ({activeTabIndex, tabs, updateWorklistNotificationCounts}) => () => {
      setLastWorklistTabName(tabs[activeTabIndex].name)
      updateWorklistNotificationCounts()
    },
    ['activeTabIndex']
  ),
  addWrapper((render, {classes}) => (
    <>
      <Grid container direction="column" className={classes.container}>
        <Grid item>{render()}</Grid>
      </Grid>
      <Fab
        component={Link}
        to={createAccountPath}
        color="primary"
        className={classes.fab}
        data-testid="worklist-fab"
      >
        <AddIcon />
      </Fab>
    </>
  )),
  ({classes, activeTabIndex, setActiveTabIndex, tabs, t}) => (
    <>
      <Tabs
        variant="fullWidth"
        value={activeTabIndex}
        onChange={setActiveTabIndex}
      >
        {tabs.map(({name, getBadge}) => (
          <Tab
            classes={{wrapper: classes.tab, labelIcon: classes.tabLabelIcon}}
            label={t(`worklist.tabs.${name}`)}
            icon={getBadge ? getBadge() : undefined}
            key={name}
          />
        ))}
      </Tabs>
      <div className={classes.itemContainer}>
        {tabs.map(({name, Component}, index) => (
          <TabPanel index={index} activeIndex={activeTabIndex} key={name}>
            <Component />
          </TabPanel>
        ))}
      </div>
    </>
  )
)

export default Worklist
