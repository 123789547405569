/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateAccountReadyForAuditInput, NjmmisCheckResult} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateAccountReadyForAudit
// ====================================================

import {deserializeUpdateAccountReadyForAuditInput} from './globalTypes'
import {serializeUpdateAccountReadyForAuditInput} from './globalTypes'
import {UpdateAccountReadyForAuditVariables as UpdateAccountReadyForAuditVariablesOrig} from '../types/UpdateAccountReadyForAudit'
import {UpdateAccountReadyForAudit as UpdateAccountReadyForAuditOrig} from '../types/UpdateAccountReadyForAudit'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UpdateAccountReadyForAudit_updateAccountReadyForAudit as UpdateAccountReadyForAudit_updateAccountReadyForAuditOrig} from '../types/UpdateAccountReadyForAudit'
export interface UpdateAccountReadyForAudit_updateAccountReadyForAudit {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdateAccountReadyForAudit_updateAccountReadyForAudit = (
  serialized: UpdateAccountReadyForAudit_updateAccountReadyForAuditOrig
): UpdateAccountReadyForAudit_updateAccountReadyForAudit => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdateAccountReadyForAudit_updateAccountReadyForAudit = (
  deserialized: UpdateAccountReadyForAudit_updateAccountReadyForAudit
): UpdateAccountReadyForAudit_updateAccountReadyForAuditOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdateAccountReadyForAudit {
  /**
   * Update existing account's ready for audit boolean
   */
  updateAccountReadyForAudit: UpdateAccountReadyForAudit_updateAccountReadyForAudit
}
export const deserializeUpdateAccountReadyForAudit = (
  serialized: UpdateAccountReadyForAuditOrig
): UpdateAccountReadyForAudit => ({
  ...serialized,
  updateAccountReadyForAudit: deserializeUpdateAccountReadyForAudit_updateAccountReadyForAudit(
    serialized.updateAccountReadyForAudit
  ),
})
export const serializeUpdateAccountReadyForAudit = (
  deserialized: UpdateAccountReadyForAudit
): UpdateAccountReadyForAuditOrig => ({
  ...deserialized,
  updateAccountReadyForAudit: serializeUpdateAccountReadyForAudit_updateAccountReadyForAudit(
    deserialized.updateAccountReadyForAudit
  ),
})
export interface UpdateAccountReadyForAuditVariables {
  account: UpdateAccountReadyForAuditInput
}
export const deserializeUpdateAccountReadyForAuditVariables = (
  serialized: UpdateAccountReadyForAuditVariablesOrig
): UpdateAccountReadyForAuditVariables => ({
  ...serialized,
  account: deserializeUpdateAccountReadyForAuditInput(serialized.account),
})
export const serializeUpdateAccountReadyForAuditVariables = (
  deserialized: UpdateAccountReadyForAuditVariables
): UpdateAccountReadyForAuditVariablesOrig => ({
  ...deserialized,
  account: serializeUpdateAccountReadyForAuditInput(deserialized.account),
})
