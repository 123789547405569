import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addWrapper,
  addState,
  addHandlers,
  addMemoBoundary,
} from 'ad-hok'
import {addPropIdentityStabilization} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addAccountAuditsQuery} from 'graphql/generated'
import AuditItem from 'components/AuditItem'
import {
  addFacilities,
  addBenefits,
  addCharityCareTypes,
} from 'utils/configContext'
import addDialogState from 'utils/addDialogState'
import AddButton from 'components/AddButton'
import AuditCsvDialog from 'components/AuditCsvDialog'

interface CreateUploadAuditCsvButtonProps {
  className?: string
  onUploadComplete: () => void
}

export const CreateUploadAuditCsvButton: FC<CreateUploadAuditCsvButtonProps> = flowMax(
  addDisplayName('CreateUploadAuditCsvButton'),
  addDialogState,
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onUploadSuccess: ({hideDialog, onUploadComplete}) => () => {
      onUploadComplete()
      hideDialog()
    },
  }),
  ({
    isShowingDialog,
    showDialog,
    hideDialog,
    onUploadSuccess,
    isSubmitting,
    className,
    t,
  }) => (
    <>
      <AddButton onClick={showDialog} className={className}>
        {t('auditCsvDialog.add')}
      </AddButton>
      <AuditCsvDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onUploadSuccess={onUploadSuccess}
        isSubmitting={isSubmitting}
      />
    </>
  )
)

const AuditWorklist: FC = flowMax(
  addDisplayName('AuditWorklist'),
  addTranslationHelpers,
  addFacilities,
  addBenefits,
  addCharityCareTypes,
  addWrapper((render) => <>{render()}</>),
  addAccountAuditsQuery({}),
  addLoadingIndicator({}),
  addPropIdentityStabilization('accountAudits'),
  addMemoBoundary(['accountAudits']),
  ({accountAudits, refetchAccountAudits}) => (
    <>
      <CreateUploadAuditCsvButton onUploadComplete={refetchAccountAudits} />
      {accountAudits.map((accountAudit) => (
        <AuditItem
          accountAudit={accountAudit}
          key={accountAudit.id}
          open={false}
        />
      ))}
    </>
  )
)

export default AuditWorklist
