/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {Benefit, McdType, McdSubtype, SlideType} from './globalTypes' // ====================================================
// GraphQL query operation: AccountAudits
// ====================================================

import {AccountAudits as AccountAuditsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits as AccountAudits_accountAuditsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_benefitOutcome as AccountAudits_accountAudits_benefitOutcomeOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application as AccountAudits_accountAudits_applicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication as AccountAudits_accountAudits_application_RyanWhiteApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person as AccountAudits_accountAudits_application_RyanWhiteApplication_personOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions as AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductionsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources as AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSourcesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession as AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards as AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes as AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application as AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_applicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication as AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication as AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication as AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers as AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbersOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession as AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards as AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication as AccountAudits_accountAudits_application_SlideApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person as AccountAudits_accountAudits_application_SlideApplication_personOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_deductions as AccountAudits_accountAudits_application_SlideApplication_person_deductionsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_incomeSources as AccountAudits_accountAudits_application_SlideApplication_person_incomeSourcesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession as AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards as AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes as AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application as AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_applicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication as AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication as AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication as AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers as AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbersOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession as AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards as AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication as AccountAudits_accountAudits_application_CharityCareApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person as AccountAudits_accountAudits_application_CharityCareApplication_personOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_deductions as AccountAudits_accountAudits_application_CharityCareApplication_person_deductionsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources as AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSourcesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession as AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards as AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes as AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application as AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_applicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication as AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication as AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication as AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers as AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbersOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession as AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards as AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication as AccountAudits_accountAudits_application_MonitorApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person as AccountAudits_accountAudits_application_MonitorApplication_personOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_deductions as AccountAudits_accountAudits_application_MonitorApplication_person_deductionsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources as AccountAudits_accountAudits_application_MonitorApplication_person_incomeSourcesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession as AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards as AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes as AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application as AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_applicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication as AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication as AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication as AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers as AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbersOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession as AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards as AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact as AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContactOrig} from '../types/AccountAudits'
import {BenefitType} from 'utils/benefits'
import {deserializeMcdSubtype} from 'graphql/converters'
import {serializeMcdSubtype} from 'graphql/converters'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {AccountAudits_accountAudits_application_MedicaidApplication as AccountAudits_accountAudits_application_MedicaidApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person as AccountAudits_accountAudits_application_MedicaidApplication_personOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_deductions as AccountAudits_accountAudits_application_MedicaidApplication_person_deductionsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources as AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSourcesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession as AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards as AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {formatISODate} from 'utils/date'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes as AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomesOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application as AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_applicationOrig} from '../types/AccountAudits'
import {deserializeSlideType} from 'graphql/converters'
import {serializeSlideType} from 'graphql/converters'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication as AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication as AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication as AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers as AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbersOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession as AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSessionOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards as AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/AccountAudits'
import {AccountAudits_accountAudits_account as AccountAudits_accountAudits_accountOrig} from '../types/AccountAudits'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {AccountAudits_accountAudits_account_documentFiles as AccountAudits_accountAudits_account_documentFilesOrig} from '../types/AccountAudits'
export interface AccountAudits_accountAudits_account_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeAccountAudits_accountAudits_account_documentFiles = (
  serialized: AccountAudits_accountAudits_account_documentFilesOrig
): AccountAudits_accountAudits_account_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeAccountAudits_accountAudits_account_documentFiles = (
  deserialized: AccountAudits_accountAudits_account_documentFiles
): AccountAudits_accountAudits_account_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface AccountAudits_accountAudits_account {
  __typename: 'Account'
  id: string
  readyForAudit: boolean
  hospitalAccountId: string | null
  documentFiles: AccountAudits_accountAudits_account_documentFiles[]
}
export const deserializeAccountAudits_accountAudits_account = (
  serialized: AccountAudits_accountAudits_accountOrig
): AccountAudits_accountAudits_account => ({
  ...serialized,
  documentFiles: serialized.documentFiles.map(
    deserializeAccountAudits_accountAudits_account_documentFiles
  ),
})
export const serializeAccountAudits_accountAudits_account = (
  deserialized: AccountAudits_accountAudits_account
): AccountAudits_accountAudits_accountOrig => ({
  ...deserialized,
  documentFiles: deserialized.documentFiles.map(
    serializeAccountAudits_accountAudits_account_documentFiles
  ),
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
): AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSessionOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession
): AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbersOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers
): AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application =
  | AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication
  | AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication
  | AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_applicationOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application'
  )
}
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application'
  )
}
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomesOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes
): AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards
): AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSessionOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession
): AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSourcesOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources
): AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_deductions = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_person_deductionsOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person_deductions => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person_deductions = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person_deductions
): AccountAudits_accountAudits_application_MedicaidApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: AccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: AccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes[]
  incomeSources: AccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources[]
  deductions: AccountAudits_accountAudits_application_MedicaidApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication_person = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplication_personOrig
): AccountAudits_accountAudits_application_MedicaidApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeAccountAudits_accountAudits_application_MedicaidApplication_person_deductions
  ),
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication_person = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication_person
): AccountAudits_accountAudits_application_MedicaidApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccountAudits_accountAudits_application_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeAccountAudits_accountAudits_application_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeAccountAudits_accountAudits_application_MedicaidApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeAccountAudits_accountAudits_application_MedicaidApplication_person_deductions
  ),
})
export interface AccountAudits_accountAudits_application_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  mcdSubtype: string | null
  person: AccountAudits_accountAudits_application_MedicaidApplication_person
}
export const deserializeAccountAudits_accountAudits_application_MedicaidApplication = (
  serialized: AccountAudits_accountAudits_application_MedicaidApplicationOrig
): AccountAudits_accountAudits_application_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
  person: deserializeAccountAudits_accountAudits_application_MedicaidApplication_person(
    serialized.person
  ),
})
export const serializeAccountAudits_accountAudits_application_MedicaidApplication = (
  deserialized: AccountAudits_accountAudits_application_MedicaidApplication
): AccountAudits_accountAudits_application_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
  person: serializeAccountAudits_accountAudits_application_MedicaidApplication_person(
    deserialized.person
  ),
})
export interface AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContactOrig
): AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact
): AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
): AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSessionOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession
): AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbersOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers
): AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application =
  | AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication
  | AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication
  | AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_applicationOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application'
  )
}
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application'
  )
}
export interface AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomesOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes
): AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards
): AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSessionOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession
): AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSourcesOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources
): AccountAudits_accountAudits_application_MonitorApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person_deductions = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_person_deductionsOrig
): AccountAudits_accountAudits_application_MonitorApplication_person_deductions => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person_deductions = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person_deductions
): AccountAudits_accountAudits_application_MonitorApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_MonitorApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: AccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: AccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes[]
  incomeSources: AccountAudits_accountAudits_application_MonitorApplication_person_incomeSources[]
  deductions: AccountAudits_accountAudits_application_MonitorApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication_person = (
  serialized: AccountAudits_accountAudits_application_MonitorApplication_personOrig
): AccountAudits_accountAudits_application_MonitorApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeAccountAudits_accountAudits_application_MonitorApplication_person_deductions
  ),
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication_person = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication_person
): AccountAudits_accountAudits_application_MonitorApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccountAudits_accountAudits_application_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeAccountAudits_accountAudits_application_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeAccountAudits_accountAudits_application_MonitorApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeAccountAudits_accountAudits_application_MonitorApplication_person_deductions
  ),
})
export interface AccountAudits_accountAudits_application_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: AccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  person: AccountAudits_accountAudits_application_MonitorApplication_person
}
export const deserializeAccountAudits_accountAudits_application_MonitorApplication = (
  serialized: AccountAudits_accountAudits_application_MonitorApplicationOrig
): AccountAudits_accountAudits_application_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeAccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  person: deserializeAccountAudits_accountAudits_application_MonitorApplication_person(
    serialized.person
  ),
})
export const serializeAccountAudits_accountAudits_application_MonitorApplication = (
  deserialized: AccountAudits_accountAudits_application_MonitorApplication
): AccountAudits_accountAudits_application_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeAccountAudits_accountAudits_application_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  person: serializeAccountAudits_accountAudits_application_MonitorApplication_person(
    deserialized.person
  ),
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
): AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSessionOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession
): AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbersOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers
): AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application =
  | AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication
  | AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication
  | AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_applicationOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application'
  )
}
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application'
  )
}
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomesOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes
): AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards
): AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSessionOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession
): AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSourcesOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources
): AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_deductions = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_person_deductionsOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person_deductions => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person_deductions = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person_deductions
): AccountAudits_accountAudits_application_CharityCareApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: AccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: AccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes[]
  incomeSources: AccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources[]
  deductions: AccountAudits_accountAudits_application_CharityCareApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication_person = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplication_personOrig
): AccountAudits_accountAudits_application_CharityCareApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeAccountAudits_accountAudits_application_CharityCareApplication_person_deductions
  ),
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication_person = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication_person
): AccountAudits_accountAudits_application_CharityCareApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccountAudits_accountAudits_application_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeAccountAudits_accountAudits_application_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeAccountAudits_accountAudits_application_CharityCareApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeAccountAudits_accountAudits_application_CharityCareApplication_person_deductions
  ),
})
export interface AccountAudits_accountAudits_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  person: AccountAudits_accountAudits_application_CharityCareApplication_person
}
export const deserializeAccountAudits_accountAudits_application_CharityCareApplication = (
  serialized: AccountAudits_accountAudits_application_CharityCareApplicationOrig
): AccountAudits_accountAudits_application_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  person: deserializeAccountAudits_accountAudits_application_CharityCareApplication_person(
    serialized.person
  ),
})
export const serializeAccountAudits_accountAudits_application_CharityCareApplication = (
  deserialized: AccountAudits_accountAudits_application_CharityCareApplication
): AccountAudits_accountAudits_application_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  person: serializeAccountAudits_accountAudits_application_CharityCareApplication_person(
    deserialized.person
  ),
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards
): AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSessionOrig
): AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession
): AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbersOrig
): AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers
): AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application =
  | AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication
  | AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication
  | AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_applicationOrig
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application'
  )
}
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application'
  )
}
export interface AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomesOrig
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes
): AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards
): AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSessionOrig
): AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession
): AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_incomeSourcesOrig
): AccountAudits_accountAudits_application_SlideApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources
): AccountAudits_accountAudits_application_SlideApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person_deductions = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_person_deductionsOrig
): AccountAudits_accountAudits_application_SlideApplication_person_deductions => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person_deductions = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person_deductions
): AccountAudits_accountAudits_application_SlideApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_SlideApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: AccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: AccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes[]
  incomeSources: AccountAudits_accountAudits_application_SlideApplication_person_incomeSources[]
  deductions: AccountAudits_accountAudits_application_SlideApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication_person = (
  serialized: AccountAudits_accountAudits_application_SlideApplication_personOrig
): AccountAudits_accountAudits_application_SlideApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeAccountAudits_accountAudits_application_SlideApplication_person_deductions
  ),
})
export const serializeAccountAudits_accountAudits_application_SlideApplication_person = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication_person
): AccountAudits_accountAudits_application_SlideApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccountAudits_accountAudits_application_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeAccountAudits_accountAudits_application_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeAccountAudits_accountAudits_application_SlideApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeAccountAudits_accountAudits_application_SlideApplication_person_deductions
  ),
})
export interface AccountAudits_accountAudits_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  slideType: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
  person: AccountAudits_accountAudits_application_SlideApplication_person
}
export const deserializeAccountAudits_accountAudits_application_SlideApplication = (
  serialized: AccountAudits_accountAudits_application_SlideApplicationOrig
): AccountAudits_accountAudits_application_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  slideType: deserializeSlideType(serialized.slideType),
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  person: deserializeAccountAudits_accountAudits_application_SlideApplication_person(
    serialized.person
  ),
})
export const serializeAccountAudits_accountAudits_application_SlideApplication = (
  deserialized: AccountAudits_accountAudits_application_SlideApplication
): AccountAudits_accountAudits_application_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  slideType: serializeSlideType(deserialized.slideType),
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  person: serializeAccountAudits_accountAudits_application_SlideApplication_person(
    deserialized.person
  ),
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbersOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication {
  __typename:
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'RyanWhiteApplication'
  id: string
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  charityCareType: string
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  slideType: string
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  slideType: deserializeSlideType(serialized.slideType),
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  slideType: serializeSlideType(deserialized.slideType),
})
export type AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application =
  | AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication
  | AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication
  | AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_applicationOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication' ||
    serialized.__typename === 'RyanWhiteApplication'
  )
    return deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application'
  )
}
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_applicationOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication' ||
    deserialized.__typename === 'RyanWhiteApplication'
  )
    return serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application'
  )
}
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomesOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSessionOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSourcesOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductionsOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions => ({
  ...serialized,
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions
): AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: AccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: AccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes[]
  incomeSources: AccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources[]
  deductions: AccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplication_personOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions
  ),
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication_person
): AccountAudits_accountAudits_application_RyanWhiteApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person_deductions
  ),
})
export interface AccountAudits_accountAudits_application_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
  person: AccountAudits_accountAudits_application_RyanWhiteApplication_person
}
export const deserializeAccountAudits_accountAudits_application_RyanWhiteApplication = (
  serialized: AccountAudits_accountAudits_application_RyanWhiteApplicationOrig
): AccountAudits_accountAudits_application_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  person: deserializeAccountAudits_accountAudits_application_RyanWhiteApplication_person(
    serialized.person
  ),
})
export const serializeAccountAudits_accountAudits_application_RyanWhiteApplication = (
  deserialized: AccountAudits_accountAudits_application_RyanWhiteApplication
): AccountAudits_accountAudits_application_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  person: serializeAccountAudits_accountAudits_application_RyanWhiteApplication_person(
    deserialized.person
  ),
})
export type AccountAudits_accountAudits_application =
  | AccountAudits_accountAudits_application_MedicaidApplication
  | AccountAudits_accountAudits_application_MonitorApplication
  | AccountAudits_accountAudits_application_CharityCareApplication
  | AccountAudits_accountAudits_application_SlideApplication
  | AccountAudits_accountAudits_application_RyanWhiteApplication
export const deserializeAccountAudits_accountAudits_application = (
  serialized: AccountAudits_accountAudits_applicationOrig
): AccountAudits_accountAudits_application => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeAccountAudits_accountAudits_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeAccountAudits_accountAudits_application_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeAccountAudits_accountAudits_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeAccountAudits_accountAudits_application_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeAccountAudits_accountAudits_application_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'AccountAudits_accountAudits_application'
  )
}
export const serializeAccountAudits_accountAudits_application = (
  deserialized: AccountAudits_accountAudits_application
): AccountAudits_accountAudits_applicationOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeAccountAudits_accountAudits_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeAccountAudits_accountAudits_application_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeAccountAudits_accountAudits_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeAccountAudits_accountAudits_application_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeAccountAudits_accountAudits_application_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'AccountAudits_accountAudits_application'
  )
}
export interface AccountAudits_accountAudits_benefitOutcome {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
}
export const deserializeAccountAudits_accountAudits_benefitOutcome = (
  serialized: AccountAudits_accountAudits_benefitOutcomeOrig
): AccountAudits_accountAudits_benefitOutcome => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeAccountAudits_accountAudits_benefitOutcome = (
  deserialized: AccountAudits_accountAudits_benefitOutcome
): AccountAudits_accountAudits_benefitOutcomeOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface AccountAudits_accountAudits {
  __typename: 'AccountAudit'
  id: string
  auditExternalId: string
  accountNumber: string
  personFirstName: string
  personLastName: string
  dateOfService: Date
  account: AccountAudits_accountAudits_account | null
  applicationCount: number
  application: AccountAudits_accountAudits_application | null
  benefitOutcome: AccountAudits_accountAudits_benefitOutcome | null
}
export const deserializeAccountAudits_accountAudits = (
  serialized: AccountAudits_accountAuditsOrig
): AccountAudits_accountAudits => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  account:
    serialized.account != null
      ? deserializeAccountAudits_accountAudits_account(serialized.account)
      : serialized.account,
  application:
    serialized.application != null
      ? deserializeAccountAudits_accountAudits_application(
          serialized.application
        )
      : serialized.application,
  benefitOutcome:
    serialized.benefitOutcome != null
      ? deserializeAccountAudits_accountAudits_benefitOutcome(
          serialized.benefitOutcome
        )
      : serialized.benefitOutcome,
})
export const serializeAccountAudits_accountAudits = (
  deserialized: AccountAudits_accountAudits
): AccountAudits_accountAuditsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  account:
    deserialized.account != null
      ? serializeAccountAudits_accountAudits_account(deserialized.account)
      : deserialized.account,
  application:
    deserialized.application != null
      ? serializeAccountAudits_accountAudits_application(
          deserialized.application
        )
      : deserialized.application,
  benefitOutcome:
    deserialized.benefitOutcome != null
      ? serializeAccountAudits_accountAudits_benefitOutcome(
          deserialized.benefitOutcome
        )
      : deserialized.benefitOutcome,
})
export interface AccountAudits {
  /**
   * List of account audits for the most recent imported csv
   */
  accountAudits: AccountAudits_accountAudits[]
}
export const deserializeAccountAudits = (
  serialized: AccountAuditsOrig
): AccountAudits => ({
  ...serialized,
  accountAudits: serialized.accountAudits.map(
    deserializeAccountAudits_accountAudits
  ),
})
export const serializeAccountAudits = (
  deserialized: AccountAudits
): AccountAuditsOrig => ({
  ...deserialized,
  accountAudits: deserialized.accountAudits.map(
    serializeAccountAudits_accountAudits
  ),
})
