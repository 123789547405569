import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addStateHandlers,
  addHandlers,
  branch,
  addEffect,
  addWrapper,
} from 'ad-hok'
import {format} from 'date-fns/fp'
import {first, truncate} from 'lodash/fp'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import {getLongDate} from 'utils/date'
import Paper from 'components/Paper'
import Grid from 'components/Grid'
import Heading from 'components/Heading'
import Tooltip from 'components/Tooltip'
import LinkButton from 'components/LinkButton'
import ApplicationTitle from 'components/ApplicationTitle'
import DisplayItem from 'components/DisplayItem'
import LabeledDisplayItem from 'components/LabeledDisplayItem'
import Caption from 'components/Caption'
import Body1 from 'components/Body1'
import {
  AccountAudits_accountAudits,
  AccountAudits_accountAudits_account_documentFiles,
} from 'graphql/deserializedTypes/AccountAudits'
import {
  addUpdateAccountReadyForAuditMutation,
  addEditableFilesQuery,
  addDeleteDocumentFileMutation,
} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addFetchDocumentFileUrl} from 'components/DocumentFileLink'
import typedAs from 'utils/typedAs'
import {EditableFileFields} from 'graphql/deserializedTypes/EditableFileFields'
import ReadOnlyPdfDialog, {
  EditableFileWithSignedUrl,
} from 'components/ReadOnlyPdfDialog'
import {EditableFiles_editableFiles} from 'graphql/deserializedTypes/EditableFiles'
import Form from 'components/Form'
import {makeFormSchema} from 'utils/form/schema'
import {
  ValidatorTest,
  makeBooleanCheckboxField,
  makeBooleanField,
  makeMultiSelectField,
  makeTextField,
} from 'utils/form/fieldTypes'
import OptionalBooleanField from 'components/OptionalBooleanField'
import SubmitButton from 'components/SubmitButton'
import HiddenField from 'components/HiddenField'
import {addFormikTyped} from 'utils/form/formik'
import {
  addFormikSubmitFormCallback,
  addSubmitForm,
} from 'utils/addFormikHoistedState'
import {addDocumentFilesContextProvider} from 'components/DocumentFiles/context'
import {AddUB04DocumentFileButton} from 'components/UB04DocumentFileButton'
import ReadOnlyFileDialogWithDelete, {
  ReadOnlyFileWithSignedUrl,
} from './ReadOnlyFileDialogWithDelete'
import {ACCOUNT_AUDITS_QUERY} from 'graphql/queries'

const classes = makeClasses((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minHeight: 130,
  },
  auditColumnsContainer: {
    flexBasis: '25%',
    marginRight: theme.spacing(2),
  },
  auditIdContainer: {
    flex: 1,
    marginRight: theme.spacing(1),
    maxWidth: 80,
  },
  titleHeader: {
    marginBottom: theme.spacing(2),
  },
  rightColumnContainer: {
    width: 200,
  },
  leftColumnsContainer: {
    flex: 1,
  },
  leftColumnContainer: {
    flex: 1,
    maxWidth: 350,
  },
  unreviewed: {
    fontWeight: 700,
  },
  reviewed: {
    fontWeight: 400,
  },
  fileLinkContainer: {
    flex: 1,
  },
  contentContainer: {
    width: 600,
  },
}))

interface FilenameLinkProps {
  editableFile: EditableFiles_editableFiles
  onClick: () => void
}

const FilenameLink: FC<FilenameLinkProps> = flowMax(
  addDisplayName('FilenameLink'),
  addProps(({editableFile: {filename}}) => ({
    filenameFormatted: truncate({length: 35}, filename),
  })),
  addClasses(classes),
  ({
    onClick,
    editableFile: {isUnreviewed, filename},
    filenameFormatted,
    classes,
  }) => (
    <Tooltip title={filename}>
      <span>
        <LinkButton
          onClick={onClick}
          className={isUnreviewed ? classes.unreviewed : classes.reviewed}
        >
          {filenameFormatted}
        </LinkButton>
      </span>
    </Tooltip>
  )
)

interface FileLinkProps {
  documentFile: AccountAudits_accountAudits_account_documentFiles
  onClick: () => void
}

const FileLink: FC<FileLinkProps> = flowMax(
  addDisplayName('FileLink'),
  addProps(({documentFile: {name}}) => ({
    filenameFormatted: truncate({length: 35}, name),
  })),
  addClasses(classes),
  ({onClick, documentFile: {name}, filenameFormatted}) => (
    <Tooltip title={name}>
      <span>
        <LinkButton onClick={onClick}>{filenameFormatted}</LinkButton>
      </span>
    </Tooltip>
  )
)

const updateReadyForAuditSchema = makeFormSchema({
  fields: {
    account: {
      id: makeTextField({isRequired: true}),
      readyForAudit: makeBooleanField({isRequired: true}),
    },
  },
})

const mustHaveAtLeastOneFileBeforeMarkingComplete: ValidatorTest = [
  'applicationDocumentDialog.mustHaveAtLeastOneFileBeforeMarkingComplete',
  function (value) {
    if (!value) return true
    const {fileIds} = this.parent
    if (!fileIds.length) return false
    return true
  },
]

export const auditDocumentFormSchema = makeFormSchema({
  fields: {
    document: {
      documentType: makeTextField({isRequired: true}),
      complete: makeBooleanCheckboxField({
        validatorTest: mustHaveAtLeastOneFileBeforeMarkingComplete,
      }),
      fileIds: makeMultiSelectField({
        isNeverNull: true,
      }),
      notes: makeTextField(),
    },
  },
})

type AddAutoselectingNewlyAddedFile = <
  TProps extends {
    open: boolean
    fileIdOptions: {
      value: string
    }[]
  }
>(
  props: TProps
) => TProps & {
  onFileDialogOpened: () => void
}

const addAutoselectingNewlyAddedFile: AddAutoselectingNewlyAddedFile = flowMax(
  addStateHandlers(
    {
      shouldAutoselectNewlyAddedFile: false,
    },
    {
      onDocumentDialogClosed: () => () => ({
        shouldAutoselectNewlyAddedFile: false,
      }),
      onFileDialogOpened: () => () => ({
        shouldAutoselectNewlyAddedFile: true,
      }),
    }
  ),
  addEffect(
    ({open, onDocumentDialogClosed}) => () => {
      if (open) return
      onDocumentDialogClosed()
    },
    ['open']
  ),
  addFormikTyped(auditDocumentFormSchema),
  addEffect(
    ({
      fileIdOptions,
      shouldAutoselectNewlyAddedFile,
      formik: {
        setFieldValue,
        values: {
          document: {fileIds},
        },
      },
    }) => () => {
      if (!shouldAutoselectNewlyAddedFile) return
      if (!fileIdOptions.length) return
      const newlyAddedFileId = first(fileIdOptions)!.value
      if (fileIds.includes(newlyAddedFileId)) return
      setFieldValue('document.fileIds', [newlyAddedFileId, ...fileIds])
    },
    ['fileIdOptions.length']
  )
)

export const getAccountFileKeyPrefix = () => `documents/accounts`

interface Props {
  accountAudit: AccountAudits_accountAudits
  label?: string
  open: boolean
}

const AuditItem: FC<Props> = flowMax(
  addDisplayName('AuditItem'),
  addProps(
    ({
      accountAudit: {
        account,
        application,
        applicationCount,
        personFirstName,
        personLastName,
      },
    }) => ({
      account,
      application,
      applicationCount,
      personLastFirstName: `${personLastName}, ${personFirstName}`,
    }),
    ['accountAudit']
  ),
  addProps(
    ({application}) => ({
      createdAt: application ? getLongDate(application.createdAt) : '',
    }),
    ['application']
  ),
  addProps(({accountAudit: {benefitOutcome}}) => ({
    benefitOutcome,
  })),
  addProps(({application}) => ({
    variables: {applicationId: application?.id || ''},
  })),
  addUpdateAccountReadyForAuditMutation({}),
  addProps(() => ({schema: updateReadyForAuditSchema})),
  branch(
    ({application}) => !!application,
    addEditableFilesQuery({
      variables: ({variables}) => variables,
    }),
    (props) => ({...props, editableFiles: []})
  ),
  addProps(({account}) => ({
    accountId: account ? account?.id : '',
    ub04DocumentFiles: account?.documentFiles || [],
  })),
  addStateHandlers(
    {
      selectedFile: typedAs<EditableFileWithSignedUrl | null>(null),
      selectedUB04File: typedAs<ReadOnlyFileWithSignedUrl | null>(null),
      selectedUB04FileId: typedAs<string | null>(null),
    },
    {
      setSelectedFile: () => (selectedFile: EditableFileWithSignedUrl) => ({
        selectedFile,
      }),
      onEditPdfDialogClose: () => () => ({
        selectedFile: null,
        selectedUB04File: null,
        selectedUB04FileId: null,
      }),
      setSelectedUB04File: () => (
        selectedUB04File: ReadOnlyFileWithSignedUrl
      ) => ({
        selectedUB04File,
      }),
      setSelectedUB04FileId: () => (selectedUB04FileId: string) => ({
        selectedUB04FileId,
      }),
    }
  ),
  addFetchDocumentFileUrl,
  addLoadingIndicator({}),
  addAppSnackbarContext,
  addTranslationHelpers,
  addSubmitForm,
  addClasses(classes),
  addWrapper((render) => (
    <Form
      name="auditDocumentForm"
      schema={auditDocumentFormSchema}
      onSubmitSuccess={() => {}}
    >
      {render()}
    </Form>
  )),
  addFormikSubmitFormCallback,
  addProps(
    () => ({
      documentFiles: [],
    }),
    []
  ),
  addProps(
    ({documentFiles}) => ({
      fileIdOptions: documentFiles.map(({id, name}) => ({
        value: id,
        label: name,
      })),
    }),
    ['documentFiles']
  ),
  addAutoselectingNewlyAddedFile,
  addProps(({accountId, ub04DocumentFiles, t}) => ({
    isFileEditingFrozen:
      ub04DocumentFiles &&
      Array.isArray(ub04DocumentFiles) &&
      ub04DocumentFiles.length > 0,
    refetchQueries: [
      {
        query: ACCOUNT_AUDITS_QUERY,
        variables: undefined,
      },
    ],
    additionalCreateDocumentFileMutateVariables: {
      accountId,
    },
    fileKeyPrefix: getAccountFileKeyPrefix(),
    documentFileDialogTitle: t(
      'accountReadyForAuditForm.documentFileDialog.documentFileDialogTitle'
    ),
    documentFiles: [],
  })),
  addDocumentFilesContextProvider,
  addDeleteDocumentFileMutation({
    refetchQueries: ({refetchQueries}) => refetchQueries,
  }),
  addHandlers({
    onSelect: ({fetchDocumentFileUrl, setSelectedFile}) => async (
      editableFile: EditableFileFields
    ) => {
      const signedUrl = await fetchDocumentFileUrl({
        fileKey: editableFile.fileKey,
      })
      setSelectedFile({...editableFile, signedUrl})
    },
    onUpdateSuccess: ({showSnackbarMessage, t}) => () => {
      showSnackbarMessage(t('accountReadyForAuditForm.updatedReadyForAudit'))
    },
    onSelectUB04File: ({
      fetchDocumentFileUrl,
      setSelectedUB04File,
      setSelectedUB04FileId,
    }) => async (
      documentFile: AccountAudits_accountAudits_account_documentFiles
    ) => {
      const signedUrl = await fetchDocumentFileUrl({
        fileKey: documentFile.fileKey,
      })
      setSelectedUB04File({...documentFile, signedUrl})
      setSelectedUB04FileId(documentFile.id)
    },
    onDeleteUB04File: ({
      selectedUB04FileId,
      t,
      showSnackbarMessage,
      onEditPdfDialogClose,
      mutateDeleteDocumentFile,
    }) => () => {
      if (selectedUB04FileId) {
        mutateDeleteDocumentFile({
          variables: {id: selectedUB04FileId},
        })
          .then(() => {
            onEditPdfDialogClose()
            showSnackbarMessage(t('applicationForm.documentFiles.deleted'))
          })
          .catch(() => {
            window.alert(t('applicationForm.documentFiles.failedToDelete'))
          })
      }
    },
  }),
  ({
    account,
    accountAudit,
    application,
    applicationCount,
    benefitOutcome,
    classes,
    createdAt,
    editableFiles,
    label,
    mutateUpdateAccountReadyForAudit,
    onEditPdfDialogClose,
    onSelect,
    onSelectUB04File,
    onDeleteUB04File,
    onUpdateSuccess,
    onFileDialogOpened,
    personLastFirstName,
    schema,
    selectedFile,
    selectedUB04File,
    ub04DocumentFiles,
    t,
  }) => (
    <>
      <Paper
        className={classes.paper}
        data-testid={`account-audit-card-${accountAudit.id}`}
      >
        <Grid container direction="row" justify="space-between">
          <div className={classes.auditColumnsContainer}>
            <Grid container direction="row" justify="space-between">
              <div className={classes.auditIdContainer}>
                <LabeledDisplayItem
                  label={t('auditItem.idNumber')}
                  value={accountAudit.auditExternalId}
                />
              </div>
              <div className={classes.leftColumnsContainer}>
                <LabeledDisplayItem
                  label={t('auditItem.accountNumber')}
                  value={accountAudit.accountNumber}
                />
                <LabeledDisplayItem
                  label={t('auditItem.name')}
                  value={personLastFirstName}
                />
                <LabeledDisplayItem
                  label={t('auditItem.serviceDischargeDate')}
                  value={`${format('M/d/yy')(accountAudit.dateOfService)}`}
                />
              </div>
            </Grid>
          </div>
          <div className={classes.leftColumnsContainer}>
            {application && (
              <Grid container direction="row" justify="space-between">
                <div className={classes.leftColumnsContainer}>
                  {label && <Caption>{label}</Caption>}
                  <Heading
                    variant="h5"
                    component="h2"
                    className={classes.titleHeader}
                  >
                    <ApplicationTitle
                      application={application}
                      shouldShowMcdType
                      shouldLinkToPerson={false}
                    />
                  </Heading>
                  <Grid container direction="row">
                    <div className={classes.leftColumnsContainer}>
                      <DisplayItem
                        i18nKey="auditItem.applicationId"
                        translations={{
                          id: application.id,
                        }}
                      />
                      <DisplayItem
                        i18nKey="auditItem.createdAt"
                        translations={{
                          createdAt,
                        }}
                      />
                    </div>
                    <div className={classes.leftColumnsContainer}>
                      <Grid item justify="space-between">
                        <LabeledDisplayItem
                          label={t('auditItem.initialDateOfService')}
                          value={
                            application.initialDateOfService
                              ? format('M/d/yyyy')(
                                  application.initialDateOfService
                                )
                              : null
                          }
                        />
                        <LabeledDisplayItem
                          label={t('auditItem.accountNumber')}
                          value="#5000210875-0001"
                        />
                      </Grid>
                    </div>
                    <div className={classes.leftColumnsContainer}>
                      {benefitOutcome && (
                        <Grid item>
                          <LabeledDisplayItem
                            label="Benefit start"
                            value={
                              benefitOutcome.effectiveStartDate
                                ? format('M/d/yyyy')(
                                    benefitOutcome.effectiveStartDate
                                  )
                                : null
                            }
                          />
                          <LabeledDisplayItem
                            label="Benefit stop"
                            value={
                              benefitOutcome.effectiveEndDate
                                ? format('M/d/yyyy')(
                                    benefitOutcome.effectiveEndDate
                                  )
                                : null
                            }
                          />
                        </Grid>
                      )}
                    </div>
                    <div className={classes.fileLinkContainer}>
                      <Grid alignItems="center">
                        {editableFiles.map(
                          (editableFile: EditableFiles_editableFiles) =>
                            editableFile.filename.includes('FINAL') && (
                              <FilenameLink
                                editableFile={editableFile}
                                onClick={() => {
                                  onSelect(editableFile)
                                }}
                              />
                            )
                        )}
                        <AddUB04DocumentFileButton
                          onDialogOpened={onFileDialogOpened}
                        />
                        {ub04DocumentFiles?.map(
                          (
                            ub04documentFile: AccountAudits_accountAudits_account_documentFiles
                          ) => (
                            <FileLink
                              documentFile={ub04documentFile}
                              onClick={() => {
                                onSelectUB04File(ub04documentFile)
                              }}
                            />
                          )
                        )}
                      </Grid>
                      <ReadOnlyPdfDialog
                        personId={application.person.id}
                        file={selectedFile}
                        onClose={onEditPdfDialogClose}
                        application={application}
                      />
                      <ReadOnlyFileDialogWithDelete
                        file={selectedUB04File}
                        onClose={onEditPdfDialogClose}
                        allowDelete={true}
                        onDelete={onDeleteUB04File}
                      />
                    </div>
                  </Grid>
                </div>

                <Grid item className={classes.rightColumnContainer}>
                  {account && (
                    <Form
                      name="accountReadyForAuditForm"
                      mutate={mutateUpdateAccountReadyForAudit}
                      schema={schema}
                      initialValues={{account}}
                      onSubmitSuccess={onUpdateSuccess}
                    >
                      <HiddenField name="account.id" />
                      <OptionalBooleanField name="account.readyForAudit" />
                      <SubmitButton testId="submit-ready-for-audit-form" />
                    </Form>
                  )}
                </Grid>
              </Grid>
            )}
            {applicationCount !== 1 && (
              <Body1>
                {t(
                  applicationCount === 0
                    ? 'auditItem.noApplicationsError'
                    : 'auditItem.multipleApplicationsError'
                )}
              </Body1>
            )}
          </div>
        </Grid>
      </Paper>
    </>
  )
)

export default AuditItem
