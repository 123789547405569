/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  UpdateApplicationStatusInput,
  Benefit,
  McdType,
  McdSubtype,
  SlideType,
} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateApplicationStatus
// ====================================================

import {deserializeUpdateApplicationStatusInput} from './globalTypes'
import {serializeUpdateApplicationStatusInput} from './globalTypes'
import {UpdateApplicationStatusVariables as UpdateApplicationStatusVariablesOrig} from '../types/UpdateApplicationStatus'
import {UpdateApplicationStatus as UpdateApplicationStatusOrig} from '../types/UpdateApplicationStatus'
import {UpdateApplicationStatus_updateApplicationStatus as UpdateApplicationStatus_updateApplicationStatusOrig} from '../types/UpdateApplicationStatus'
import {UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication as UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplicationOrig} from '../types/UpdateApplicationStatus'
import {deserializeSlideType} from 'graphql/converters'
import {serializeSlideType} from 'graphql/converters'
import {UpdateApplicationStatus_updateApplicationStatus_SlideApplication as UpdateApplicationStatus_updateApplicationStatus_SlideApplicationOrig} from '../types/UpdateApplicationStatus'
import {UpdateApplicationStatus_updateApplicationStatus_CharityCareApplication as UpdateApplicationStatus_updateApplicationStatus_CharityCareApplicationOrig} from '../types/UpdateApplicationStatus'
import {UpdateApplicationStatus_updateApplicationStatus_MonitorApplication as UpdateApplicationStatus_updateApplicationStatus_MonitorApplicationOrig} from '../types/UpdateApplicationStatus'
import {UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact as UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdateApplicationStatus'
import {BenefitType} from 'utils/benefits'
import {deserializeMcdSubtype} from 'graphql/converters'
import {serializeMcdSubtype} from 'graphql/converters'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {formatISODate} from 'utils/date'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {UpdateApplicationStatus_updateApplicationStatus_MedicaidApplication as UpdateApplicationStatus_updateApplicationStatus_MedicaidApplicationOrig} from '../types/UpdateApplicationStatus'
export interface UpdateApplicationStatus_updateApplicationStatus_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  mcdSubtype: string | null
}
export const deserializeUpdateApplicationStatus_updateApplicationStatus_MedicaidApplication = (
  serialized: UpdateApplicationStatus_updateApplicationStatus_MedicaidApplicationOrig
): UpdateApplicationStatus_updateApplicationStatus_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
})
export const serializeUpdateApplicationStatus_updateApplicationStatus_MedicaidApplication = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus_MedicaidApplication
): UpdateApplicationStatus_updateApplicationStatus_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
})
export interface UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact = (
  serialized: UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContactOrig
): UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact
): UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdateApplicationStatus_updateApplicationStatus_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
}
export const deserializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication = (
  serialized: UpdateApplicationStatus_updateApplicationStatus_MonitorApplicationOrig
): UpdateApplicationStatus_updateApplicationStatus_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
})
export const serializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus_MonitorApplication
): UpdateApplicationStatus_updateApplicationStatus_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
})
export interface UpdateApplicationStatus_updateApplicationStatus_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
}
export const deserializeUpdateApplicationStatus_updateApplicationStatus_CharityCareApplication = (
  serialized: UpdateApplicationStatus_updateApplicationStatus_CharityCareApplicationOrig
): UpdateApplicationStatus_updateApplicationStatus_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
})
export const serializeUpdateApplicationStatus_updateApplicationStatus_CharityCareApplication = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus_CharityCareApplication
): UpdateApplicationStatus_updateApplicationStatus_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
})
export interface UpdateApplicationStatus_updateApplicationStatus_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  slideType: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
}
export const deserializeUpdateApplicationStatus_updateApplicationStatus_SlideApplication = (
  serialized: UpdateApplicationStatus_updateApplicationStatus_SlideApplicationOrig
): UpdateApplicationStatus_updateApplicationStatus_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  slideType: deserializeSlideType(serialized.slideType),
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
})
export const serializeUpdateApplicationStatus_updateApplicationStatus_SlideApplication = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus_SlideApplication
): UpdateApplicationStatus_updateApplicationStatus_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  slideType: serializeSlideType(deserialized.slideType),
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
})
export interface UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication {
  __typename: 'RyanWhiteApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  dateOfApplication: Date | null
  coveragePeriod: number | null
}
export const deserializeUpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication = (
  serialized: UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplicationOrig
): UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
})
export const serializeUpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication
): UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
})
export type UpdateApplicationStatus_updateApplicationStatus =
  | UpdateApplicationStatus_updateApplicationStatus_MedicaidApplication
  | UpdateApplicationStatus_updateApplicationStatus_MonitorApplication
  | UpdateApplicationStatus_updateApplicationStatus_CharityCareApplication
  | UpdateApplicationStatus_updateApplicationStatus_SlideApplication
  | UpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication
export const deserializeUpdateApplicationStatus_updateApplicationStatus = (
  serialized: UpdateApplicationStatus_updateApplicationStatusOrig
): UpdateApplicationStatus_updateApplicationStatus => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdateApplicationStatus_updateApplicationStatus_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdateApplicationStatus_updateApplicationStatus_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdateApplicationStatus_updateApplicationStatus_SlideApplication(
      serialized
    )
  if (false || serialized.__typename === 'RyanWhiteApplication')
    return deserializeUpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdateApplicationStatus_updateApplicationStatus'
  )
}
export const serializeUpdateApplicationStatus_updateApplicationStatus = (
  deserialized: UpdateApplicationStatus_updateApplicationStatus
): UpdateApplicationStatus_updateApplicationStatusOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdateApplicationStatus_updateApplicationStatus_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdateApplicationStatus_updateApplicationStatus_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdateApplicationStatus_updateApplicationStatus_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdateApplicationStatus_updateApplicationStatus_SlideApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'RyanWhiteApplication')
    return serializeUpdateApplicationStatus_updateApplicationStatus_RyanWhiteApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdateApplicationStatus_updateApplicationStatus'
  )
}
export interface UpdateApplicationStatus {
  /**
   * Update existing application's status
   */
  updateApplicationStatus: UpdateApplicationStatus_updateApplicationStatus
}
export const deserializeUpdateApplicationStatus = (
  serialized: UpdateApplicationStatusOrig
): UpdateApplicationStatus => ({
  ...serialized,
  updateApplicationStatus: deserializeUpdateApplicationStatus_updateApplicationStatus(
    serialized.updateApplicationStatus
  ),
})
export const serializeUpdateApplicationStatus = (
  deserialized: UpdateApplicationStatus
): UpdateApplicationStatusOrig => ({
  ...deserialized,
  updateApplicationStatus: serializeUpdateApplicationStatus_updateApplicationStatus(
    deserialized.updateApplicationStatus
  ),
})
export interface UpdateApplicationStatusVariables {
  application: UpdateApplicationStatusInput
}
export const deserializeUpdateApplicationStatusVariables = (
  serialized: UpdateApplicationStatusVariablesOrig
): UpdateApplicationStatusVariables => ({
  ...serialized,
  application: deserializeUpdateApplicationStatusInput(serialized.application),
})
export const serializeUpdateApplicationStatusVariables = (
  deserialized: UpdateApplicationStatusVariables
): UpdateApplicationStatusVariablesOrig => ({
  ...deserialized,
  application: serializeUpdateApplicationStatusInput(deserialized.application),
})
